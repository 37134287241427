import areas1 from "./../../img/areas1.png";
import areas2 from "./../../img/areas2.png";
import areas3 from "./../../img/areas3.png";
import areas4 from "./../../img/areas4.png";
import areas5 from "./../../img/areas5.png";
import areas6 from "./../../img/areas6.png";
import areas7 from "./../../img/areas7.png";
import areas8 from "./../../img/areas8.png";
import areas9 from "./../../img/areas9.png";
import areas10 from "./../../img/areas10.png";
import areas11 from "./../../img/areas11.png";
import areas12 from "./../../img/areas12.png";
import areas13 from "./../../img/areas13.png";
import areas14 from "./../../img/areas14.png";
import areas15 from "./../../img/areas15.png";
import areas16 from "./../../img/areas16.png";
import areas17 from "./../../img/areas17.png";
import areas18 from "./../../img/areas18.png";
import areas19 from "./../../img/areas19.png";
import areas20 from "./../../img/areas20.png";

const listAreas = {
    bachelor: {
      title: "БАКАЛАВРИАТ",
      about:"Основополагающее высшее образование, дающее базовые знания по экономике и начальные навыки анализа.",
      directions: [
        {
          departmentTitle: "Кафедра бизнес-информатики и математических методов в экономике",
          direction: [
            {
              directionName: "Бизнес-информатика",
              code: "38.03.05",
              areas: [
                {
                  image:areas1,
                  areasTitle: "Применение и разработка информационных систем в экономике",
                  form: "очная",
                  term: "4 года",
                  link: "/areas/areas1"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "Кафедра экономики и менеджмента",
          direction: [
            {
              directionName: "Менеджмент",
              code: "38.03.02",
              areas: [
                {
                  image:areas2,
                  areasTitle: "Управление персоналом",
                  form: "очная",
                  term: "4 года",
                  link: "/areas/areas2"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "Кафедра бухгалтерского учета и аудита",
          direction: [
            {
              directionName: "Экономика",
              code: "38.03.01",
              areas: [
                {
                  image:areas3,
                  areasTitle: "Бухгалтерский учет, анализ и аудит",
                  form: "очная",
                  term: "4 года",
                  link: "/areas/areas3"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "Кафедра экономической теории и мировой экономики",
          direction: [
            {
              directionName: "Экономика",
              code: "38.03.01",
              areas: [
                {
                  image:areas4,
                  areasTitle: "Внешнеэкономические связи и таможенный консалтинг",
                  form: "очная",
                  term: "4 года",
                  link: "/areas/areas4"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "Кафедра финансов и кредита",
          direction: [
            {
              directionName: "Экономика",
              code: "38.03.01",
              areas: [
                {
                  image:areas5,
                  areasTitle: "Налоги и налоговый консалтинг",
                  form: "очная",
                  term: "4 года",
                  link: "/areas/areas5"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "Кафедра финансов и кредита",
          direction: [
            {
              directionName: "Экономика",
              code: "38.03.01",
              areas: [
                {
                  image:areas6,
                  areasTitle: "Финансы и кредит",
                  form: "очная",
                  term: "4 года",
                  link: "/areas/areas6"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "Кафедра экономики и менеджмента",
          direction: [
            {
              directionName: "Экономика",
              code: "38.03.01",
              areas: [
                {
                  image:areas7,
                  areasTitle: "Экономика и менеджмент",
                  form: "очная",
                  term: "4 года",
                  link: "/areas/areas7"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "",
          direction: [
            {
              directionName: "Экономика",
              code: "38.03.01",
              areas: [
                {
                  image:areas8,
                  areasTitle: "Экономика социально-трудовых отношений",
                  form: "очная",
                  term: "4 года",
                  link: "/areas/areas8"
                }
              ]
            }
          ]
        }
      ]
    },
    specialist: {
      title: "СПЕЦИАЛИТЕТ",
      about:"Углублённое высшее образование с акцентом на практическую подготовку в выбранной сфере экономики.",
      directions: [
        {
          departmentTitle: "",
          direction: [
            {
              directionName: "Экономическая безопасность",
              code: "38.05.01",
              areas: [
                {
                  image:areas9,
                  areasTitle: "Экономико-правовое обеспечение экономической безопасности",
                  form: "",
                  term: "",
                  link: "/areas/areas9"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "Кафедра финансов и кредита",
          direction: [
            {
              directionName: "Экономическая безопасность",
              code: "38.05.01",
              areas: [
                {
                  image:areas10,
                  areasTitle: "Финансово-экономическая безопасность",
                  form: "очная, заочная",
                  term: "5 лет (очная), 5 лет и 6 месяцев (заочная)",
                  link: "/areas/areas10"
                }
              ]
            }
          ]
        }
      ]
    },
    magisters: {
      title: "МАГИСТРАТУРА",
      about:"Продвинутое высшее образование, направленное на развитие исследовательских и аналитических навыков в экономике.",
      directions: [
        {
          departmentTitle: "",
          direction: [
            {
              directionName: "Менеджмент",
              code: "",
              areas: [
                {
                  image:areas11,
                  areasTitle: "Управление и разработка информационными системами в экономике",
                  form: "",
                  term: "",
                  link:"/areas/areas11"
                },
              ]
            }
          ]
        },
        {
          departmentTitle: "Кафедра экономики и менеджмента",
          direction: [
            {
              directionName: "Менеджмент",
              code: "38.04.02",
              areas: [
                {
                  image:areas12,
                  areasTitle: "Экономика и управление на предприятии (в организации)",
                  form: "заочная",
                  term: "2 года и 6 месяцев",
                  link:"/areas/areas12"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "Кафедра финансов и кредита",
          direction: [
            {
              directionName: "Финансы и кредит",
              code: "38.04.08",
              areas: [
                {
                  image:areas13,
                  areasTitle: "Финансовая экономика и монетарное регулирование",
                  form: "очная, заочная",
                  term: "2 года (очная), 2 года и 6 месяцев (заочная)",
                  link:"/areas/areas13"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "Кафедра финансов и кредита",
          direction: [
            {
              directionName: "Экономика",
              code: "5.38.04.01",
              areas: [
                {
                  image:areas14,
                  areasTitle: "Аудит и финансовый консалтинг",
                  form: "заочная",
                  term: "2 года и 6 месяцев",
                  link:"/areas/areas14"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "Кафедра финансов и кредита",
          direction: [
            {
              directionName: "Экономика",
              code: "5.38.04.01",
              areas: [
                {
                  image:areas15,
                  areasTitle: "Бухгалтерский учет, анализ и аудит в отраслях",
                  form: "заочная",
                  term: "2 года и 6 месяцев",
                  link:"/areas/areas15"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "Кафедра финансов и кредита",
          direction: [
            {
              directionName: "Экономика",
              code: "38.04.01",
              areas: [
                {
                  image:areas16,
                  areasTitle: "Международные финансы и банки",
                  form: "очная, заочная",
                  term: "2 года (очная), 2 года и 6 месяцев (заочная)",
                  link:"/areas/areas16"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "Кафедра экономической теории и мировой экономики",
          direction: [
            {
              directionName: "Экономика",
              code: "38.04.01",
              areas: [
                {
                  image:areas17,
                  areasTitle: "Международная экономика",
                  form: "заочная",
                  term: "2 года и 6 месяцев",
                  link:"/areas/areas17"
                }
              ]
            }
          ]
        }
      ]
    },
    graduate: {
      title: "АСПИРАНТУРА",
      about:"Программа подготовки научных кадров, направленная на проведение оригинальных исследований и получение степени кандидата наук.",
      directions: [
        {
          departmentTitle: "",
          direction: [
            {
              directionName: "",
              areas: [
                {
                  image:areas20,
                  areasTitle: "Региональная и отраслевая экономика",
                  code: "",
                  form: "",
                  term: "",
                  link:"/areas/*"
                }
              ]
            }
          ]
        }
      ]
    },
    /*retraining: {
      title: "ПРОГРАММЫ ПЕРЕПОДГОТОВКИ",
      directions: [
        {
          departmentTitle: "",
          direction: [
            {
              directionName: "",
              areas: [
                {
                  image:areas18,
                  areasTitle: "Бухгалтерский учет, анализ и аудит",
                  code: "",
                  form: "",
                  term: "",
                  link:"/areas/*"
                }
              ]
            }
          ]
        },
        {
          departmentTitle: "",
          direction: [
            {
              directionName: "",
              areas: [
                {
                  image:areas19,
                  areasTitle: "Управление и экономика в здравоохранении",
                  code: "",
                  form: "",
                  term: "",
                  link:"/areas/*"
                }
              ]
            }
          ]
        }
      ]
    }*/
  };
  
  export default listAreas;
  