// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-management {
    max-width: 960px;
    margin: 50px auto 20px;
}

.grou {
    color: #7a7a7a;
    font-size: 20px;
}

.container-management__items {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.management__item {
    display: flex;
    gap: 40px;
    align-items: center;
    padding: 10px 100px;
    box-sizing: border-box;
}

.management__item-text {
    line-height: 2;
}
`, "",{"version":3,"sources":["webpack://./src/pages/management/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".container-management {\r\n    max-width: 960px;\r\n    margin: 50px auto 20px;\r\n}\r\n\r\n.grou {\r\n    color: #7a7a7a;\r\n    font-size: 20px;\r\n}\r\n\r\n.container-management__items {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 40px;\r\n}\r\n\r\n.management__item {\r\n    display: flex;\r\n    gap: 40px;\r\n    align-items: center;\r\n    padding: 10px 100px;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.management__item-text {\r\n    line-height: 2;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
