import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Users from "../../components/Users";

const Studentproject = () => {
    return (
        <>
        <Header/>
        <main>
        <div className="container-newscouncil">
            <Users type = "МИССЭКОНОМ" title = "Мисс эконом"/>
        </div>

        <div className="container-newscouncil">
            <Users type = "ПРОФРАЗВИТИЕ" title = "Твое профессиональное развитие"/>
        </div>

        </main>
        <Footer/>
        </>
    )
}

export default Studentproject;