import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AreasNew from "./AreasNew";

const Areas = () => {
    return ( <>
    <Header/>
    <main>
        <AreasNew />
    </main>
    <Footer/>
    </> );
}
 
export default Areas;