import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";
import smolenskii from "./../../img/smolenskii.png";
import Dep from "./Dep";
import DepTable from "./DepTable";

const Department4 = () => {
    const allDepartments = Dep();
    const ecimenDepartment = allDepartments.find(department => department.departmentTitle === "Кафедра экономики и менеджмента");
    return ( <>
    <Header />
    <div className="container-departments">
    <h1>Кафедра экономики и менеджмента</h1>
            <div className="department1">
                <p>
                <ul>
                    <li>3300, г. Тирасполь, бульвар Гагарина 2, Корпус 11, каб. № 211,212</li>
                    <li>533-79477</li>
                    <li>cafedra.ekonomika@yandex.ru</li>
                </ul>
                <span style={{color: '#43a5ee', fontWeight: 'bold', display: 'block', textAlign: 'center'}}>ОСНОВАНИЕ, НАЗВАНИЯ И СТАТУС КАФЕДРЫ</span>
                Кафедра экономики и менеджмента экономического факультета Приднестровского государственного университета имени Т.Г.Шевченко, созданная 01 сентября 1993 года, за эти годы носила три названия:<br/>
                <ul style={{listStyleType: 'none'}}>
                    <li>- в период с 01 сентября 1993 года по 14 октября 2004 года это была кафедра экономики и управления предприятиями отраслей народного хозяйства в составе экономического факультета;</li>
                </ul>
                </p>
                <figure>
                    <img src={smolenskii} className="righting" width="400px"/>
                    <figcaption style={{lineHeight: '2'}}><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Смоленский Николай Николаевич</span>, кандидат экономических наук, доцент, заведующий кафедрой</figcaption>
                </figure>
            </div>
            
            <p>
                <ul style={{listStyleType: 'none'}}>                    
                    <li>- в период с 15 октября 2004 года по 31 августа 2012 года - кафедра национальной экономики в составе экономического факультета;</li>
                    <li>- в период с 01 сентября 2012 года по настоящее время - кафедра экономики и менеджмента в составе экономического факультета.</li>
                </ul>
                Все эти годы кафедра экономики и менеджмента является выпускающей, то есть готовящей специалистов для различных отраслей экономики.
            </p>
            <br/> 

            <p><span style={{color: '#43a5ee', fontWeight: 'bold', display: 'block', textAlign: 'center'}}>ЗАВЕДУЮЩИЕ КАФЕДРОЙ</span>
            В разные годы кафедру экономики и менеджмента возглавляли:
            <ul style={{listStyleType: 'none'}}>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Евгений Александрович Литвинов, </span>доктор экономических наук, профессор, работавший в период с 1993 года по 1996 год;</li>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Василий Васильевич Николаев, </span>кандидат экономических наук, профессор, работавший в период с 1996 года по 1998 год;</li>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Григорий Никандрович Грицюк, </span>доктор экономических наук, профессор, работавший в период с 1998 года по 2007 год;</li>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Ольга Ивановна Грицюк, </span>кандидат экономических наук, доцент, работавшая в период с 2007 года по 2010 год;</li>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}> </span></li>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Виталий Иванович Глебов, </span>доктор экономических наук, профессор, работавший в период с 2010 года по 2013 год;</li>
                <li><span style={{color: '#43a5ee', fontWeight: 'bold'}}>Николай Николаевич Смоленский, </span>кандидат экономических наук, доцент, работающий в период с 2013 года по настоящее время.</li>
            </ul >

            <span style={{color: '#43a5ee', fontWeight: 'bold', display: 'block', textAlign: 'center'}}>ПРЕПОДАВАТЕЛИ КАФЕДРЫ</span>
            В течение всего периода преподавателями и специалистами кафедры экономики и менеджмента являлись <span style={{color: '#43a5ee', fontWeight: 'bold'}}>пятьдесят девять</span> человек, из которых <span style={{color: '#43a5ee', fontWeight: 'bold'}}>шесть</span> докторов экономических наук и профессоров и <span style={{color: '#43a5ee', fontWeight: 'bold'}}>пятнадцать</span> кандидатов экономических наук и доцентов.<br/>
            В этот период преподавателями кафедры было защищено <span style={{color: '#43a5ee', fontWeight: 'bold'}}>шесть</span> диссертаций на соискание ученой степени кандидата экономических наук.
            В настоящее время на кафедре экономики и менеджмента работает <span style={{color: '#43a5ee', fontWeight: 'bold'}}>восемнадцать</span> преподавателей, из которых <span style={{color: '#43a5ee', fontWeight: 'bold'}}>три</span> являются докторами экономических наук и <span style={{color: '#43a5ee', fontWeight: 'bold'}}>семь</span> – кандидатами экономических наук, а также два специалиста.<br/>
            Нынешний ректор Приднестровского государственного университета имени Т.Г.Шевченко <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Владислав Владимирович Соколов</span> также является профессором кафедры экономики и менеджмента.<br/>
            Для кафедры экономики и менеджмента характерна стабильность преподавательского состава. Так из нынешних <span style={{color: '#43a5ee', fontWeight: 'bold'}}>восемнадцати</span> преподавателей <span style={{color: '#43a5ee', fontWeight: 'bold'}}>семь</span> входят в состав коллектива свыше двадцати лет. Это <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Николай Николаевич Смоленский, Светлана Анатольевна Гребенюк, Лариса Васильевна Савенко, Екатерина Владимировна Курпатенко, Лина Павловна Маляренко, Наталья Андреевна Мороз</span> и <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Алла Николаевна Струнгар.</span>
            <span style={{color: '#43a5ee', fontWeight: 'bold', display: 'block', textAlign: 'center'}}>УЧЕБНАЯ, МЕТОДИЧЕСКАЯ И ОРГАНИЗАЦИОННАЯ РАБОТА КАФЕДРЫ</span>
            Важнейшим направлением деятельности любой кафедры является <span style={{color: '#43a5ee', fontWeight: 'bold'}}>учебная, методическая и организационная работа</span> по закрепленным за кафедрой учебным дисциплинам, направлениям (профилям и магистерским программам) подготовки и специальностям (специализациям).<br/>
            На кафедре экономики и менеджмента в разные годы осуществлялась и осуществляется подготовка студентов по специальности «Экономика и управление предприятиями отраслей народного хозяйства» и «Национальная экономика»; бакалавров по профилю «Экономика и менеджмент» направления «Экономика», профилям «Производственный менеджмент в сфере агропромышленного комплекса», «Управление персоналом» и «Экономика социально-трудовых отношений» направления «Менеджмент» и профилю «Коммерция» направления «Торговое дело».<br/>
            Помимо этого, на кафедре осуществляется подготовка магистров по магистерским программам «Производственный менеджмент в отраслях и комплексах» и «Экономика и управления на предприятии» направления «Менеджмент».
            Также на кафедре открыта аспирантура по специальностям «Экономика и управление народным хозяйством» и «Региональная и отраслевая экономика».

            <span style={{color: '#43a5ee', fontWeight: 'bold', display: 'block', textAlign: 'center'}}>ВЫПУСКНИКИ КАФЕДРЫ</span>
            Кафедрой экономики и менеджмента осуществлено двадцать восемь выпусков студентов на очном отделении и двадцать шесть выпусков студентов на заочном отделении, в результате которых было подготовлено <span style={{color: '#43a5ee', fontWeight: 'bold'}}>1074</span> специалиста, из которых <span style={{color: '#43a5ee', fontWeight: 'bold'}}>671</span> – на очном отделении и <span style={{color: '#43a5ee', fontWeight: 'bold'}}>403</span> – на заочном отделении.<br/>
            Из <span style={{color: '#43a5ee', fontWeight: 'bold'}}>671</span> выпускника очного отделения <span style={{color: '#43a5ee', fontWeight: 'bold'}}>328</span> стали специалистами по специальности «Экономика и управление предприятиями отраслей народного хозяйства и <span style={{color: '#43a5ee', fontWeight: 'bold'}}>242</span> – по специальности «Национальная экономика», <span style={{color: '#43a5ee', fontWeight: 'bold'}}>59</span> стали бакалаврами по бакалаврской программе «Экономика и менеджмент» и <span style={{color: '#43a5ee', fontWeight: 'bold'}}>31</span> – по бакалаврской программе «Производственный менеджмент в агропромышленном комплексе», <span style={{color: '#43a5ee', fontWeight: 'bold'}}>11</span> стали магистрами по магистерской программе «Производственный менеджмент в отраслях и комплексах».<br/>
            Из <span style={{color: '#43a5ee', fontWeight: 'bold'}}>403</span> выпускников заочного отделения <span style={{color: '#43a5ee', fontWeight: 'bold'}}>182</span> стали специалистами по специальности «Экономика и управление предприятиями отраслей народного хозяйства и <span style={{color: '#43a5ee', fontWeight: 'bold'}}>166</span> – по специальности «Национальная экономика», <span style={{color: '#43a5ee', fontWeight: 'bold'}}>45</span> стали бакалаврами по бакалаврской программе «Экономика и менеджмент» и <span style={{color: '#43a5ee', fontWeight: 'bold'}}>6</span> – по бакалаврской программе «Коммерция», <span style={{color: '#43a5ee', fontWeight: 'bold'}}>4</span> стали магистрами по магистерской программе «Производственный менеджмент в отраслях и комплексах».<br/>
            Среди выпускников кафедры хочется отметить тех, кто добился значительных успехов в своей профессиональной деятельности.<br/>
            Это <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Алевтина Алексеевна Слинченко, </span> продолжающая работу в республиканских исполнительных органах власти и работавшая в должности первого заместителя Председателя Правительства Приднестровской Молдавской Республики и Министра экономического развития Приднестровской Молдавской Республики.<br/>
            Это <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Алексей Алексеевич Цуркан, </span> продолжающий работу в республиканских исполнительных органах власти и работавший в должности заместителя Председателя Правительства Приднестровской Молдавской Республики и Министра здравоохранения Приднестровской Молдавской Республики.<br/>
            Это <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Владислав Сергеевич Тидва, </span> возглавляющий в настоящее время Приднестровский Республиканский банк.<br/>
            Это <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Владимир Григорьевич Трандасир, </span> являющийся в настоящее время генеральным директором одного из крупнейших приднестровских предприятий – научно-производственного закрытого акционерного общества «Электромаш».<br/>
            Это <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Иван Николаевич Узун</span> и <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Дмитрий Александрович Поросеч,</span> защитившие диссертации на соискание ученой степени кандидата экономических наук и возглавлявшие в разное время экономический факультет Приднестровского государственного университета имени Т.Г.Шевченко.<br/><br/>
            С целью методического обеспечения учебного процесса преподавателями кафедры экономики и менеджмента в период с 2006 года по настоящее время подготовлено и издано <span style={{color: '#43a5ee', fontWeight: 'bold'}}>девятнадцать</span>девятнадцать учебных, учебно-методических пособий и практикумов.

            <span style={{color: '#43a5ee', fontWeight: 'bold', display: 'block', textAlign: 'center'}}>НАУЧНАЯ РАБОТА КАФЕДРЫ</span>
            Не менее важное направление деятельности кафедры – это <span style={{color: '#43a5ee', fontWeight: 'bold'}}>научно-исследовательская работа в области экономической науки.</span><br/>
            Преподаватели кафедры экономики и менеджмента <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Иван Васильевич Васильев, Светлана Александровна Уманец, Дмитрий Александрович Поросеч, Николай Николаевич Смоленский, Наталья Владимировна Терзи и Светлана Анатольевна Гребенюк</span> в период с 2001 года по 2013 год защитили шесть диссертаций на соискание ученой степени кандидата экономических наук.<br/>
            Преподавателями кафедры экономики и менеджмента издано пять монографий, являющихся научным трудом в виде книги с углубленным изучением одной темы или нескольких тесно связанных тем.<br/>
                <ul style={{listStyleType: 'none'}}>                    
                    <li>- «Производственно-экономический потенциал Приднестровья», изданная <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Григорием Никандровичем Грицюком</span> в 1995 году;</li>
                    <li>- «Предпринимательство и рынок ценных бумаг: становление, развитие», изданная <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Владимиром Петровичем Зубрицким</span> в 1998 году;</li>
                    <li>- «Регулирование рынка труда в Приднестровской Молдавской Республике в условиях трансформируемой экономики», изданная <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Николаем Николаевичем Смоленским</span> в 2009 году;</li>
                    <li>- «Профессиональная адаптация выпускников высших учебных заведений в современных условиях», изданная <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Натальей Владимировной Терзи</span> в 2010 году;</li>
                    <li>- «Управление трудовыми ресурсами региона и пути его совершенствования», изданная <span style={{color: '#43a5ee', fontWeight: 'bold'}}>Дмитрием Александровичем Поросечем</span> в 2012 году;</li>
                </ul>
                Преподаватели кафедры экономики и менеджмента ежегодно публикуют в различных сборниках научных форумов, научных молдавских и российских журналах, а также в журнале «Экономика Приднестровья» большое количество научных статей, касающихся различных аспектов экономической науки и практики.<br/>
                Также на кафедре экономики и менеджмента с 2018 года издается ежегодный научный бюллетень «Производственный менеджмент в отраслях и комплексах: магистерские исследования», в котором размещают свои научные статьи слушатели кафедральных магистерских программ. В 2023 году вышел из печати уже пятый выпуск этого бюллетеня.<br/>
                На экономическом факультете, по инициативе ученых кафедры экономической теории, после некоторого перерыва была возобновлена 3 ноября 2010 года практика проведения научных семинаров С этого времени ежегодно учеными факультета проводятся научные семинары, за каждый из которых отвечает та, либо иная кафедра.<br/>
                    <ul style={{listStyleType: 'none'}}>Кафедра экономики и менеджмента в этот период также выступила организатором проведения восьми научных семинаров, посвященных различным темам, среди которых:                    
                        <li>- «О предмете купли-продажи на рынке труда: рабочая сила или труд» в 2011 году;</li>
                        <li>- «Проблемы и перспективы развития потребительского рынка и товародвижения в Приднестровье» в 2013 году;</li>
                        <li>- «Управление инновационным развитием экономики в современных условиях» в 2015 году;</li>
                        <li>- «Объект продажи на рынке труда: рабочая сила или труд» в 2016 году;</li>
                        <li>- «Управление агропромышленным комплексом в современных условиях: проблемы и перспективы» в 2017 году;</li>
                        <li>- «Модель рынка труда в Приднестровье» в 2018 году;</li>
                        <li>- Актуальные направления управления предприятием: современные тенденции и перспективы развития» в 2020 и 2021 годах.</li>
                    </ul>
            </p>
        </div>

    <div className="container-departments">
        <DepTable department={ecimenDepartment} /> {/* Передаем данные кафедры */}
    </div>
        
    <Footer />
    </> );
}
 
export default Department4;